.successHeader img {
  max-width: 175px;
}

.successBody {
  max-width: 800px;
  margin: 0 auto;
}

.successBody .dateWrapper {
  padding: 5px 20px;
  display: inline-block;
  border: 1px solid var(--yellowOpacity10);
  border-radius: 5px;
}

.successBody .dateWrapper p {
  color: var(--light);
}

.successBody .dateWrapper p span {
  color: var(--yellow);
}

.seperator svg {
  max-width: 100%;
  width: 100%;
}

.successBody>p span {
  color: var(--white);
}

.successBody .txId p {
  padding: 10px 15px;
  border-radius: 5px;
  border: 1px solid var(--whiteOpacity10);
}

.successBody .txId .copyTxId svg path {
  stroke: var(--yellow);
}

.successBody .txId p span {
  color: var(--white);
}

.successFooter .shareWrapper .shareWrapperImg {
  border: 1px solid var(--yellowOpacity10);
  box-shadow: 1px 1px 20px var(--yellowOpacity10);
  width: 425px;
  border-radius: 15px;
  overflow: hidden;
}

.successFooter .shareWrapper .shareWrapperContent {
  position: relative;
}

.successFooter .shareWrapper .shareWrapperContent .pointingArrowGif {
  position: absolute;
  top: 30px;
  animation: arrowMovement 0.8s ease-in-out infinite;
}

@keyframes arrowMovement {
  0% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(5px);
  }

  100% {
    transform: translateY(0px);
  }
}

.successFooter .shareWrapper .shareWrapperImg::-webkit-scrollbar {
  width: 2px;
}

.successFooter .shareWrapper .shareWrapperImg::-webkit-scrollbar-track {
  background: var(--yellowOpacity10);
}

.successFooter .shareWrapper .shareWrapperImg::-webkit-scrollbar-thumb {
  background: var(--yellow);
}