.mainSection {
  padding: 30px 0 0;
  overflow: auto;
  min-height: calc(100vh - 65px);
}

.mainSectionbody {
  padding: 25px;
  border-radius: 10px;
  box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.5);
  background-color: var(--blackOpacity10);
}

.separator {
  width: 2px;
  height: 25px;
  background-color: var(--grey);
  margin: 0 10px;
}

.mainSectionFooter p {
  color: var(--white);
}

.mainSectionFooter p svg {
  margin-right: 10px;
}

.mainSectionFooter p svg path {
  stroke: var(--yellow);
}

.mainSectionFooter .left,
.mainSectionFooter .right {
  box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  height: 80px;
}

.mainSectionFooter .right span {
  color: var(--silver);
  margin-right: 10px;
}

.container {
  padding: 0 20px;
  max-width: 1360px;
  margin: 0 auto;
}

.offerWrapper {
  max-width: 900px;
  margin: 40px auto 20px;
}

.offerWrapper .ant-row {
  /* align-items: center; */
  border-radius: 20px;
  outline: 1px solid var(--yellowOpacity10);
  overflow: hidden;
  padding: 25px;
  background: linear-gradient(to bottom, var(--yellowOpacity10), transparent);
}

.offerWrapper .left {
  padding-left: 40px;
}

.offerWrapper .left span {
  color: var(--white);
}

.offerWrapper .left h1 span {
  color: var(--yellow);
}

.offerWrapper .left p span {
  color: var(--white);
}

.offerWrapper .left .dateWrapper {
  padding: 5px 20px;
  display: inline-block;
  border: 1px solid var(--yellowOpacity10);
  border-radius: 5px;
}

.offerWrapper .left .dateWrapper p {
  color: var(--light);
}

.offerWrapper .left .dateWrapper p span {
  color: var(--yellow);
}

.offerWrapper .left button svg path {
  fill: var(--dark);
}

.offerWrapper .left .gift svg {
  position: relative;
  top: -3px;
}

.offerWrapper .left .gift {
  align-items: self-end;
}

.offerWrapper .left .gift p {
  color: var(--white);
}

.offerWrapper .right {
  position: relative;
}

.offerWrapper .right:hover::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  z-index: 0;
}

.offerWrapper .right:hover .imgDetails {
  opacity: 1;
  z-index: 1;
}

.offerWrapper .right img {
  border-radius: 20px;
  width: 100%;
  height: 100%;
  transition: 0.2s all ease-in-out;
}

.offerWrapper .right .imgDetails {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: 0.2s all ease-in-out;
}

.offerWrapper .right .imgDetails h1 {
  color: var(--yellow);
}

.offerWrapper .right .imgDetails p {
  color: var(--yellow);
  border: 1px solid var(--yellow);
  border-radius: 30px;
  padding: 5px 25px;
  line-height: normal;
}

.offerWrapper .right .imgDetails a:hover {
  color: var(--grey);
}

.offerWrapper .right .imgDetails a svg {
  transition: 0.2s all ease-in-out;
}

.offerWrapper .right .imgDetails a:hover svg {
  transform: translate(2px, -2px);
}

.offerWrapper .right .imgDetails a svg path {
  fill: var(--grey);
}

.downloadApps .downloadLabel a {
  height: 22px;
}

.downloadApps .downloadLabel svg {
  width: 22px;
  height: 22px;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
  height: auto;
}

.downloadApps .downloadLabel svg:hover {
  transform: scale(1.1);
}

/* --------------------tooltip icon--------------- */

@keyframes bolt {
  0% {
    transform: translate(0%, 0%);
  }

  50% {
    transform: translate(0, -30px);
  }

  100% {
    transform: translate(0%, 0%);
  }
}

/* -------------------------Loader css here------------- */

.loader-logo {
  background: none;
  width: 100px;
  height: auto;
  position: absolute;
  top: 48%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@-ms-keyframes spin {
  from {
    -ms-transform: rotate(0deg);
  }

  to {
    -ms-transform: rotate(360deg);
  }
}

@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0deg);
  }

  to {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 767px) {
  .lds-ripple {
    left: 38%;
  }
}


/* -----------Media Quaries-------- */

@media (max-width:1200px) {
  .mainSectionFooter .ant-row {
    flex-direction: column;
    gap: 10px;
    align-items: center;
  }

  .mainSectionFooter .ant-row .ant-col {
    width: 100%;
    flex: 1;
    max-width: 100%;
  }
}

@media (max-width:767px) {
  .mainSection {
    padding: 20px 0 0;
    height: unset;
  }

  .mainSectionbody {
    padding: 15px;
    border-radius: unset;
  }

  .headerSection h1 {
    font-size: 35px;
  }

  .headerSection span {
    font-size: 27px;
  }

  .headerSection .boltGif {
    height: 27px;
  }

  .formWrapper .formFields {
    margin-bottom: 15px;
  }

  .formWrapper .networkSelection {
    padding: 15px;
    height: 50px;
  }

  .checkboxWrapper .checkboxWrapperItm span,
  .formWrapper .networkSelection p {
    font-size: 16px;
  }

  .formWrapper .checkboxWrapper {
    margin-top: 5px;
  }

  .formoutput .left a,
  .formoutput .right p {
    font-size: 16px;
  }

  .formoutput .right {
    margin-left: unset;
  }

  .mainSectionFooter img {
    flex: 1;
  }

  .offerWrapper .ant-row {
    padding: 20px;
  }

  .offerWrapper .ant-col {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .offerWrapper .left {
    padding-left: 0px;
    padding-top: 40px;
  }

}

@media (max-width:575px) {
  .formoutput {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }

  .mainSectionFooter {
    margin-bottom: 20px;
  }

  .mainSectionFooter .left,
  .mainSectionFooter .right {
    height: auto;
    padding: 20px;
    flex-direction: column;
  }

  .mainSectionFooter .left .content svg {
    width: 100%;
    max-width: 100%;
  }

  .mainSectionFooter .right .separator {
    display: none;
  }

  .mainSectionFooter .right .content {
    margin-top: 20px;
    flex-direction: column;
    gap: 15px;
  }

  .appDownloadWrapper {
    flex-direction: column;
  }
}

.dflex {
  display: flex;
}

.justify-between {
  justify-content: space-between;
}

.align-center {
  align-items: center;
}