.headerSection {
    text-align: center;
}

.boltIcon img {
    width: 600px;
    border-radius: 50%;
    position: fixed;
    top: -50px;
    left: -150px;
    animation: bolt 3s ease-in-out 0s infinite normal;
    z-index: -2;
    opacity: 0.05;
}

.bbLogoShadow {
    position: fixed;
    top: 0px;
    right: 10px;
    z-index: -2;
}

.headerSection h1 {
    font-size: 55px;
    letter-spacing: -2px;
    width: fit-content;
    margin: 0 auto;
}

.headerSection span {
    font-size: 45px;
    letter-spacing: -2px;
    font-weight: 400;
}

.headerSection .boltGif {
    height: 42px;
    margin-left: 10px;
    position: relative;
    top: 6px;
}

@media (max-width:767px) {
    .boltIcon img {
        opacity: 0.03;
    }

    .bbLogoShadow {
        display: none;
    }
}