.leaderboardWrapper .formFields {
    margin-bottom: 0;
    width: 100%;
    max-width: 400px;
}

.leaderboardWrapper .formFields .fgBox input {
    padding: 15px;
}

.leaderboardWrapper .formFields .fgBox input:focus {
    border-color: var(--yellow);
}

.leaderboardWrapper .leaderboardHeader {
    padding: 15px 0;
}
.lbTableScroll .ant-table-content{height: 600px; overflow: auto; }
.walletAddress .MdText {
    min-width: 120px;
}

.walletAddress svg path {
    stroke: var(--silver);
}

.walletAddress svg,
.walletAddress .lift {
    transition: 0.2s all ease-in-out;
}

.walletAddress svg:hover {
    opacity: 0.5;
}

.walletAddress .lift:hover {
    transform: translate(4px, -4px);
}