* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: var(--background);
  font-family: "Inter", sans-serif !important;
}

:root {
  --black: #000;
  --background: #0d0d0d;
  --white: #ffffff;
  --light: #f5f5f5;
  --silver: #eeeeee;
  --grey: #808080;
  --dark: #050404;
  --darkSilver: #464646;
  --blackOpacity10: rgba(0, 0, 0, 0.1);
  --whiteOpacity10: rgba(255, 255, 255, 0.1);
  --whiteOpacity05: rgba(255, 255, 255, 0.05);
  --success: #4bb543;
  --successOpacity10: rgb(75 181 67 / 10%);
  --neon: #02F4D2;
  --neonOpacity10: rgb(2, 244, 210, 0.1);
  --logoGradient: linear-gradient(to right, #6C17FC, #02F4D2);
  --revLogoGradient: linear-gradient(to right, #02F4D2, #6C17FC);
  --lightGradient: linear-gradient(to right, #02F4D2, #04BBFB);
  --yellow: #F7BA31;
  --yellowOpacity10: rgba(247, 188, 49, 0.1);
  --yellowLight: #FFE5AA;
  --yellowGradient: linear-gradient(to right, #F7BA31, #FFE5AA, #F7BA31);
}

::placeholder,
.ant-select-selection-placeholder {
  font-size: 18px !important;
  font-weight: 400 !important;
  color: var(--grey) !important;
  opacity: 1 !important;
  font-family: "Inter", sans-serif !important;
  padding: 20px 50px 20px 0px;
}

.svgIcon {
  cursor: pointer;
}

img {
  max-width: 100%;
}

/* ----------------select--------------- */

.ant-select {
  height: 63px;
  width: 100%;
}

.ant-select .ant-select-selector {
  background: transparent !important;
  border: 1px solid var(--whiteOpacity10) !important;
  font-size: 18px;
  padding: 20px !important;
  color: var(--white);
  border-radius: 10px;
}

.ant-select .ant-select-selector:hover,
.ant-select .ant-select-selector:focus,
.ant-select .ant-select-selector:focus-within {
  border-color: var(--neon) !important;
}

.ant-select .ant-select-arrow {
  color: var(--darkSilver);
}

.ant-select-dropdown {
  border: 1px solid var(--whiteOpacity10);
  box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.5);
  background-color: var(--background) !important;
}

.ant-select-dropdown .ant-select-item-option-content {
  color: var(--light) !important;
  font-size: 16px !important;
  padding: 10px 0;
}

.ant-select-selection-item {
  color: var(--light) !important;
  font-weight: 600 !important;
}

.ant-select-dropdown .ant-select-item-option-active {
  background: var(--whiteOpacity05) !important;
}

.ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background: var(--whiteOpacity10) !important;
}

/* ----------------Checkbox--------------- */

.ant-checkbox .ant-checkbox-inner {
  background-color: transparent;
  border-color: var(--whiteOpacity10);
  padding: 8px;
}

.ant-checkbox-wrapper-checked .ant-checkbox .ant-checkbox-inner {
  background: var(--background) !important;
  border-color: var(--whiteOpacity10);
}

.ant-checkbox .ant-checkbox-inner:hover {
  background-color: var(--background);
}

.ant-checkbox .ant-checkbox-inner:after {
  left: 5px;
}

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner {
  border-color: var(--whiteOpacity10) !important;
}

/* ----------------tabs--------------- */

.ant-tabs {
  height: 63px;
  border: 1px solid var(--whiteOpacity10);
  border-radius: 10px;
  overflow: hidden;
}

.ant-tabs-nav {
  height: 100%;
}

.ant-tabs .ant-tabs-tab+.ant-tabs-tab,
.ant-tabs>.ant-tabs-nav {
  margin: 0;
}

.ant-tabs .ant-tabs-ink-bar,
.ant-tabs-top>.ant-tabs-nav::before {
  display: none;
}

.ant-tabs>.ant-tabs-nav .ant-tabs-nav-list {
  width: 100% !important;
}

.ant-tabs .ant-tabs-tab {
  width: 100% !important;
  justify-content: center;
  height: 100%;
}

.ant-tabs .ant-tabs-tab.ant-tabs-tab-active {
  background-color: var(--whiteOpacity10);
}

.ant-tabs .ant-tabs-tab span {
  font-size: 18px;
  color: var(--white);
  font-weight: 600;
}

.ant-tabs .ant-tabs-tab-btn {
  display: flex;
  align-items: center;
}

.ant-tabs .ant-tabs-tab-btn .ant-tabs-tab-icon {
  display: flex;
  align-items: center;
}

.ant-tabs .ant-tabs-tab-btn[aria-selected="false"] span {
  color: var(--grey);
}

.ant-tabs .ant-tabs-tab-btn[aria-selected="false"] .ant-tabs-tab-icon svg path {
  fill: var(--grey);
}

.ant-tabs .ant-tabs-tab-btn .ant-tabs-tab-icon:not(:last-child) {
  margin-right: 10px;
}

/* ----------------Tooltip--------------- */

.ant-tooltip .ant-tooltip-inner {
  background-color: var(--background);
  position: relative;
  box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.5);
  border: 1px solid var(--whiteOpacity10);
}

.ant-tooltip .ant-tooltip-arrow {
  display: none !important;
}

/* -----------Common----------- */


.cursorPointer {
  cursor: pointer;
}

.dFlex {
  display: flex !important;
}

.dBlock {
  display: block !important;
}

.inlineFlex {
  display: inline-flex;
}

.flexWrap {
  flex-wrap: wrap;
}

.alignCenter {
  align-items: center;
}

.alignStretch {
  align-items: stretch;
}

.alignBaseline {
  align-items: baseline;
}

.alignStart {
  align-items: start;
}

.justifyStart {
  justify-content: flex-start;
}

.justifyCenter {
  justify-content: center;
}

.justifyEnd {
  justify-content: end;
}

.justifyBetween {
  justify-content: space-between;
}

.flexCol {
  flex-direction: column;
}

.flexColRev {
  flex-direction: column-reverse;
}

.flexRow {
  flex-direction: row;
}

.flexRowRev {
  flex-direction: row-reverse;
}

.gap5 {
  gap: 5px;
}

.gap10 {
  gap: 10px;
}

.gap15 {
  gap: 15px;
}

.gap20 {
  gap: 20px;
}

.gap30 {
  gap: 30px;
}

.gap75 {
  gap: 75px;
}

.gap100 {
  gap: 100px;
}
.me5 {
  margin-right: 5px;
}
.mb5 {
  margin-bottom: 5px;
}

.mb10 {
  margin-bottom: 10px;
}

.mb20 {
  margin-bottom: 20px;
}

.mb30 {
  margin-bottom: 30px;
}

.mb50 {
  margin-bottom: 50px;
}

.mb75 {
  margin-bottom: 75px;
}

.mt10 {
  margin-top: 10px;
}

.mt20 {
  margin-top: 20px;
}

.mt30 {
  margin-top: 30px;
}

.mt50 {
  margin-top: 50px;
}

.mt75 {
  margin-top: 75px;
}

.textUpper {
  text-transform: uppercase;
}

.textCapitalize {
  text-transform: capitalize;
}

.textCenter {
  text-align: center;
}

.textLeft {
  text-align: left;
}

.textRight {
  text-align: right;
}

.sectionSpacing {
  padding: 100px 0;
}

.textUnderline {
  text-decoration: underline;
}

.fontNormal {
  font-weight: 400 !important;
}

.fontMedium {
  font-weight: 500 !important;
}

.fontSemiBold {
  font-weight: 600 !important;
}

.fontBold {
  font-weight: 700 !important;
}

.wFull {
  width: 100%;
}

.hFull {
  height: 100%;
}

/* ------------texts----- */

.XSmText {
  font-size: 12px;
  font-weight: normal;
  color: var(--grey);
}

.SmText {
  font-size: 14px;
  font-weight: normal;
  color: var(--grey);
}

.NmText {
  font-size: 16px;
  color: var(--grey);
  font-weight: normal;
  line-height: 26px;
}

.MdText {
  color: var(--grey);
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
}

.XMdText {
  color: var(--grey);
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
}

.LgText {
  color: var(--white);
  font-size: 24px;
  font-weight: 600;
}

.XLgText {
  color: var(--white);
  font-size: 32px;
  font-weight: bold;
}

.linkText {
  background-image: var(--lightGradient);
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  font-size: 16px;
  font-weight: bolder;
  border-bottom: 1px solid transparent;
}

.linkText:hover {
  border-bottom: 1px solid var(--neon);
}

.lightGradient {
  background-image: var(--lightGradient);
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
}

.yellowGradient {
  background-image: var(--yellowGradient);
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
}

.yLinkText {
  background-image: var(--yellowGradient);
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  font-size: 16px;
  font-weight: bolder;
  border-bottom: 1px solid transparent;
}

.yLinkText:hover {
  border-bottom: 1px solid var(--yellow);
}

/* ------------animation----------- */

@keyframes PulsePoint-pulse-OnK {
  0% {
    opacity: 0;
    transform: scale(0);
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: scale(1);
  }
}

/* ----------------Media Quaries start--------------- */

@media (max-width:767px) {

  ::placeholder,
  .ant-select-selection-placeholder,
  .ant-select .ant-select-selector,
  .ant-tabs .ant-tabs-tab-btn span {
    font-size: 16px !important;
  }

  .ant-select .ant-select-selector {
    padding: 15px !important;
  }

  .ant-select,
  .ant-tabs {
    height: 50px;
  }

  .XLgText {
    font-size: 27px;
  }

  .LgText {
    font-size: 20px;
  }

  .MdText {
    font-size: 16px;
    line-height: 24px;
  }

  .NmText {
    font-size: 14px;
    line-height: 24px;
  }
}

/* --- modal --- */

/* .ant-modal .ant-modal-content {
  background-color: transparent !important;
} */

.ant-modal .ant-modal-content {
  padding: 20px;
}

.ant-modal .ant-modal-content .ant-modal-footer {
  display: none;
}

.ant-modal .ant-modal-close {
  color: var(--grey);
}

.ant-modal .ant-modal-close:hover {
  color: var(--grey);
}

.iframewrapper.ant-modal .ant-modal-content {
  background-color: var(--black);
  padding: 0;
}

/* -------------ant-table------------- */

.ant-table-wrapper .ant-table {
  background: transparent;
  border: 1px solid var(--whiteOpacity10);
  border-radius: 8px;
}

.ant-table-wrapper .ant-table-thead>tr>th,
.ant-table-wrapper .ant-table-tbody>tr>td {
  background: transparent;
  border: none;
}

.ant-table-wrapper .ant-table-tbody>tr>td {
  border-bottom: 1px solid var(--whiteOpacity05);
  padding: 20px;
}

.ant-table-cell-row-hover {
  background: transparent !important;
}

.ant-table-wrapper .ant-table-tbody>tr:hover {
  background: linear-gradient(to right, transparent, var(--whiteOpacity10), transparent) !important;
}

.ant-table-wrapper .ant-table-thead>tr>th {
  color: var(--dark);
  font-size: 18px;
  background: var(--yellow);
  border-bottom: 1px solid var(--whiteOpacity10);
}

.ant-table-wrapper .ant-table-thead>tr>th:last-child {
  text-align: center;
}

.ant-table-wrapper .ant-table-thead>tr>th:nth-child(2) {
  text-align: center;
}

.ant-table-wrapper .ant-table-thead>tr>th::before {
  display: none;
}

.ant-table-wrapper .ant-table-tbody>tr.ant-table-placeholder {
  background: transparent !important;
}

.ant-table-wrapper .ant-table-tbody>tr:hover{
  background: transparent !important;
}

.ant-table-wrapper .ant-table-tbody>tr.ant-table-placeholder:hover>td {
  background: transparent !important;
}

.ant-empty-normal .ant-empty-description {
  color: white;
}